import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './screens/Home';
import Club from './screens/Club';
import Professeur from './screens/Professeur';
import Adaniya from './screens/Adaniya';
import Karate from './screens/Karate';
import Kobudo from './screens/Kobudo';

import Banner from './assets/banner.jpg';
import SideBar from './screens/SideBar';
import Okinawa from './screens/Okinawa';
import Videos from './screens/Videos';
import Videoskarate from './screens/Videoskarate';
import Videoskobudo from './screens/Videoskobudo';
import Contact from './screens/Contact';
import Liens from './screens/Liens';

export default function App() {
  return (
    <div className="w-screen justify-around h-screen sm:overflow-y-hidden">
      <div className="w-screen sm:h-small bg-black">
        <img src={Banner} className="w-full h-full object-scale-down" alt="" />
      </div>
      <div className="w-screen flex sm:flex-row flex-col justify-between">
        <SideBar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/club">
            <Club />
          </Route>
          <Route path="/professeur">
            <Professeur />
          </Route>
          <Route path="/adaniya">
            <Adaniya />
          </Route>
          <Route path="/karate">
            <Karate />
          </Route>
          <Route path="/kobudo">
            <Kobudo />
          </Route>
          <Route path="/okinawa">
            <Okinawa />
          </Route>
          <Route path="/videos">
            <Videos />
          </Route>
          <Route path="/videoskarate">
            <Videoskarate />
          </Route>
          <Route path="/videoskobudo">
            <Videoskobudo />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/liens">
            <Liens />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
