import React from 'react';

import { Header, SubHeader, generalText, b, Image, blank } from '../components';
import Wrapper from './ScreenWrapper';

import shorin from '../assets/DSCN3382.jpg';
import kobudo from '../assets/DSCN3392.jpg';
import Dojo from '../assets/Dojo Avant seine .jpg';

export default function Club() {
  return (
    <Wrapper>
      {Header('Le club')}
      {Image(Dojo)}
      {SubHeader('Présentation')}
      <div className={`${generalText} mt-2`}>
        Le Uchina-di club propose l'enseignement du karaté shorin-ryu et du
        kobudo d'Okinawa.
        <br />
        <br />
        C'est un club d'arts martiaux anciens avec une pratique traditionnelle.
        <br />
        <br />
        Le club ainsi que les cours de karate et de kobudo sont dirigés par le
        professeur {b('Grégory GUERIN, 5e DAN de Karaté Shorin Ryu et 5e DAN de Kobudo.')}      
        <br />
        <br />
        La pratique est fondée sur la progression personnelle et sur
        l'efficacité des techniques apprises.
      </div>

      {SubHeader('Karaté shorin-ryu')}
      <div className={`${generalText} mt-2`}>
        Ce style ancien est souple, varié et particuliérement efficace pour le
        combat rapproché.
      </div>
      {Image(shorin)}

      {SubHeader("Kobudo d'Okinawa")}
      <div className={`${generalText} mt-2`}>
        Art de manier les armes, cette discipline propose un grand panel d'armes
        différentes.
      </div>
      {Image(kobudo)}

      <div className={`${generalText} mt-2`}>
        Quatre armes de base sont pratiquées dans les débuts :
      </div>

      <div className="mt-2">
        <li className={generalText}>Bo</li>
        <li className={generalText}>Sai</li>
        <li className={generalText}>Tunfa</li>
        <li className={generalText}>Nunchaku</li>
      </div>

      {blank()}
    </Wrapper>
  );
}
