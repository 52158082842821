import React from 'react';

import { Header, generalText, BigImage, b } from '../components';
import Wrapper from './ScreenWrapper';

import japan from '../assets/2000px-Japan_location_map.svg.jpg';
import okinawa from '../assets/OKINAWA map.jpg';
import temple from '../assets/DSC02104.jpeg';
import beach from '../assets/beach.jpg';
import shurijo from '../assets/Naha_Shuri_Castle51s3s4200.jpg';

export default function Okinawa() {
  return (
    <Wrapper>
      {Header('Okinawa')}
      <div className={generalText}>
        <div className="w-full flex justify-around my-4">
          <img alt="" src={japan} className="w-2/5 object-scale-down" />
          <img alt="" src={okinawa} className="w-2/5 object-scale-down" />
        </div>
        L'{b("île d'Okinawa")} (沖縄本島, Okinawa hontō, littéralement « île principale Okinawa ») (okinawaïen : 沖縄 translit. : uchinā) est l'île principale de l'archipel Okinawa, et donc de la préfecture d'Okinawa et de l'archipel des îles Ryūkyū.
        <br />
        <br />
        Sa ville principale est Naha, capitale de la préfecture. Elle est située à 1 554 km à vol d'oiseau au sud-ouest de Tokyo.
        <br />
        <br />
        Elle fût le berceau du karaté et du kobudo. l'un étant l'art du combat à mains nue et l'autre avec les armes et outils de tous les jours.
        <br />
        <br />
        A Okinawa les habitants sont extrêmement hospitaliers et sont réputés pour leur gentillesse.
        <br />
        À peine un septième de la corse en superficie et pourtant l'île ne compte pas moins de 200 dojos et une trentaine d'école différentes.
        <br /> 
        Tous les styles de karaté sont nés ici, où y ont leurs racines.
        Pour tout pratiquant de karaté et de kobudo, Okinawa reste un pèlerinage  qui faut accomplir au moins une fois dans sa vie pour voir à quel point 
        le karaté et le kobudo ont imprégnés la vie de tous les jours.
        <br />
        <br />
        Le karaté et le kobudo ne se pratiquent pas seulement au dojo mais aussi dans la vie de tous les jours, il faut avoir été à Okinawa pour s'en persuader ; ici travail, patience, courage, persévérance, respect des autres ne sont pas des mots vides de sens.
        <br />
        <br />
        Okinawa se trouve géographiquement à l'extrême sud du japon, le climat y est subtropical avec des périodes de typhon de juin à octobre.
        
      </div>
      {BigImage(temple)}
      <div className="w-full flex justify-around my-4">
        {b('Shureimon (守礼門, ou Shurei no mon)')}
      </div>

      {BigImage(beach)}
      <div className="w-full flex justify-around my-4">
        {b('Plage et shīsā (lion gardien)')}
      </div>
      {BigImage(shurijo)}
      <div className="w-full flex justify-around my-4">
        {b('Le château de Shuri (首里城, Shuri-jō)')}          
      </div>
    </Wrapper>
  );
}
