import React from 'react';
import Wrapper from './ScreenWrapper';
import { Header, generalText, link, BigImage } from '../components';

import location from '../assets/location.png';

export default function Contact() {
  return (
    <Wrapper>
      {Header('Addresse')}
      <div className={generalText}>
        3 Rue du Martin Pêcheur, 92190 Meudon
        <br />
        <br />
        Téléphone: {link('06-51-69-03-69', 'tel:06-51-69-03-69')}
        <br />
        E-Mail: {link('bureau@uchinadiclub.com', 'mailto:bureau@uchinadiclub.com')}
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d781.0119307372701!2d2.238208512567892!3d48.82005130127541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67bbc52d4d797%3A0x4b17cf668c233e58!2sL&#39;Avant+Seine!5e0!3m2!1sfr!2sfr!4v1562020553087!5m2!1sfr!2sfr" className="w-full h-full"></iframe>
    </Wrapper>
  );
}
