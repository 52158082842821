import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { b, generalText } from '../components';
import useWindowSize from '../useWindowSize'

export default function SideBar() {
  const buttonClass =
    'w-full p-1 bg-gray-800 hover:bg-gray-700 font-nunito tracking-wide font-bold my-1 text-gray-200 text-xs';

  const {width} = useWindowSize();
  const [docked, setDocked] = useState((width > 640));

  const history = useHistory();

  const listItems = [
    { name: 'Accueil', link: '/' },
    { name: 'Club', link: '/club' },
    { name: 'Professeur', link: '/professeur' },
    { name: 'Adaniya Sensei', link: '/adaniya' },
    { name: 'Karate Shorin-ryu', link: '/karate' },
    { name: "Kobudo d'Okinawa", link: '/kobudo' },
    { name: 'Okinawa', link: '/okinawa' },
    { name: 'Videos', link: '/videos' },
    { name: 'Videos karate', link: '/videoskarate' },
    { name: 'Videos kobudo', link: '/videoskobudo' },
    { name: 'Contact', link: '/contact' },
    { name: 'Liens', link: '/liens' },
  ];

  let hamburger = (width > 640 ? null : (
    <div className="w-full flex justify-center">
      <button
        className="ri-menu-fill text-xl hover:bg-gray-600 focus:bg-gray-600 hover:bg-gray-600 p-2 rounded-full"
        onClick={() => setDocked((prev) => !prev)}
      ></button>
    </div>
  ));

  const list = listItems.map((item) => (
    <button
      key={`sidenav-${item.link}`}
      className={buttonClass}
      onClick={() => history.push(item.link)}
    >
      {item.name}
    </button>
  ));

  return (
    <div className="sm:w-1/6 w-5/6 sm:my-0 my-4 sm:h-screen bg-gray-300 sm:ml-2 sm:mr-0 mx-auto sm:p-2 px-2 pt-2">
      {docked ? (
        hamburger
      ) : (
        <div>
          {list}
          {hamburger}
        </div>
      )}
      <div className={`${generalText} flex flex-col justify-center mt-2`}>
        <div className="mx-auto">{b('HORAIRES')}</div>
        <br />
        <div className="mx-auto">{b('KARATE')}</div>
        <br />Pour les adultes:
        <br />- lundi de 20h15 à 21h45
        <br />Pour les adultes et les enfants (+7 ans) :
        <br />- Samedi de 13h30 à 14h30
        <br />
        <div className="mx-auto">{b('KOBUDO')}</div> 
        <br />Pour les adultes et adolescents (+14 ans)
        <br />- Mardi de 20h45 à 22h00
        <br />- Samedi de 14h30 à 15h30      
      </div>

      <div className="w-11/12 mx-auto my-1 h-20 flex justify-between">
              <div className="flex flex-col h-full w-2/3 text-blue-800 items-center justify-center">
          <a className="overflow-hidden h-1/3 hover:underline focus:underline" href="https://www.facebook.com/Uchina-di-club-karat%C3%A9-et-kobudo-dOkinawa-1485281791613789/" title="Uchina-di club karaté et kobudo d'Okinawa">Uchina-di club </a>
          <a className="w-full h-1/3" href="https://www.facebook.com/Uchina-di-club-karat%C3%A9-et-kobudo-dOkinawa-1485281791613789/">
            <button className="w-full bg-gray-200 hover:bg-gray-300 focus:bg-gray-300 h-full overflow-hidden px-1 text-xs flex justify-center items-center">
              <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAXVBMVEVCZ7L///8+ZbEqWKw8Y7D6+vxee7t5j8RKbLQvW60yXa5QcbY5YbDb4e+Jncvs7/bi5/LS2eqWp9CdrdPH0OZXd7lkgL6xvtyQos69yOFrhsCsudq3w9+Blsijstbb3sEkAAAD6UlEQVR4nO3dW3OqMBSG4SQEJSEa8Xxq///P3KJ19u7sjkZpWGtlvvemdwzPgIAksUp/1S2PjSql5rjq7jB1+7MzPhhDvWO/lrHBqd0/wrhx1PuUIbeJd+HMW+q9yZINi5sw+nJOz+8ZG6/Ctswj2Gc3vfDkqfcjY/58EdpSz9E+02i1LvEy+jfXqX2g3omshZXalnySXq41U1XOo9qPmZZ6DxBCCCGEEEIIIYSQmK4vAwsa+rtnbAjeu6qqbNM0ygZf9TnnnfchWGtEo613ars8dV2s9bdiXHTderda7Y9ta5zM9/XGumq6W+jn1TEKfNtrvJ2vE3RftdLOVOs2h/q5S6zQVNvuOUqu0Pj2VZ8soXWnl32ihH4S3wDKEZpq9Y5PkNC/cIOQKDR+9iZQiPA6r6dkofEpT2iShdXrd0FZQnceAJQgDPMhQAFC0w4CChC6t+8TQoRhPwzIXmj8QCB7od+VLlRDgdyFww8hc6Fxg4HMhYMvpOyFbsATtwjh0McZ/sLwWbrwN05S3sLhN0PmwvBRutC/+M03xsXs/xrGwhc+hvV6OTHupzivujMh+eDNnQsCF2eZTSLw0wV5ur7UC81U5kC26ldcpQHlLo70hxTgQewR7NcFpgglr99Nesl2kHuOKlWljMYcJR/DKmHKRS30PnErRTgTfJ1JE4r+GCYJP0Uvwk4R7iVfaJKEH8Ufw4/ij+EcQtZBCCH/IISQfxBCyD8IIeQfhBDyD0IIeWQelCQMj7ZgGCx1njyoTRAuN4+2cIn4Vy3N5LlhaFXpwli8sKMduhlBSDw4NYJwRTuwMYJwXrxwQvtIMIKQeGZifmFNPM6fX7goXrgmnsmQX3guXrgkHufPL5wSf3/ML6Sexp5fSD1lKrswUk97yy4kn9iXXUg+sS+7kHxiX3Yh+cvG7MJt8ULydWu5hTXti7YRhOS3w+xC6u9O+YXkt8PsQurvTvmF9LPAcwvJbxbZhfQLZzMLI/mFJreQ+lVifiH97fBS86CQMMo9fbQFRf4xfFLKTIWH79K4A4cL2QchhPyDEEL+QQgh/yCEkH8QQsg/CCHkH4QQ8g9CCPkHIYT8gxBC/kEIIf8ghJB/EELIPwgh5B+EEPIPQgj5ByGE/IMQQv5BCCH/IISQfxBCyD8IIeQfhBDyD0IIX4joJzLHEppGHQsXHtWS5mQfSxiWqqP5nZexhK5Tuuiz1Cit9Inkp2xGErrzRahbik/iOEK70b1w4QhO1FGE1sarUM/8+EdxDGGwC30T6tj6sQ9jfqFx26jvQq1P1ttR/0FUXqGxwTWH21a+hLru9tvmNwlPyitsjqvuvpU/gCBIQ+BZEzIAAAAASUVORK5CYII=" className="w-2 mx-1" />
              J'aime cette Page
            </button>
          </a>
        </div>
      </div>
      <div className="h-full flex flex-col justify-center w-1/4">
          <a href="https://www.facebook.com/Uchina-di-club-karaté-et-kobudo-dOkinawa-1485281791613789/">
            <img alt="" className="w-16 ml-1" src="https://scontent.fmru5-1.fna.fbcdn.net/v/t1.0-9/67183835_1512099628932005_9217167320940544_n.jpg?_nc_cat=111&_nc_sid=85a577&_nc_ohc=6WhtpEBJVd0AX_mriVd&_nc_ht=scontent.fmru5-1.fna&oh=86b697ecd1b8ce24ff0775082e380159&oe=5F89315F" />
          </a>
        </div>
    </div>
  );
}
