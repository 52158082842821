import React from 'react';
import Wrapper from './ScreenWrapper';
import { Header, SubHeader, b, BigImage, link, generalText } from '../components';

import Carte from '../assets/Carte dojo KORYUKAI.jpg';

export default function Liens() {
  return (
    <Wrapper>
      {Header('Liens')}
      {SubHeader('Dojos KORYUKAI')}
      <div className="mt-2 list-disc">
        <li className={generalText}>
          {b('Le Nippon Budo Club (NBC) - honbu dojo (本部 道場)-')}
          <div className="list-disc ml-6">
            <li className={generalText}>
              Dojo de sensei Adaniya
              <div className="list-dics ml-6">
                <li className={generalText}>
                  {link('Page Internet', 'https://www.nipponbudoclub.com/')}
                </li>
                <li className={generalText}>
                  {link(
                    'Page Facebook',
                    'https://www.facebook.com/NipponBudoClub/'
                  )}
                </li>
                <li className={generalText}>
                  Adresse : Gymnase Rosa Parks rue du moulin au lapin 75014
                  Paris
                </li>
              </div>
            </li>
          </div>
        </li>
      </div>

      <div className="mt-2 list-disc">
        <li className={generalText}>
          {b('Le Uchinadi dojo (Ile Maurice)')}
          <div className="list-disc ml-6">
            <li className={generalText}>
              Dojo de Frédéric Guérin
              <div className="list-disc ml-6">
                <li className={generalText}>
                  {link('Page Facebook', 'https://www.facebook.com/uchinadi/')}
                </li>
                <li className={generalText}>
                  Adresse : 17 roland maurel street, Pailles, Mauritius
                </li>
              </div>
            </li>
          </div>
        </li>
      </div>

      <div className="mt-2 list-disc">
        <li className={generalText}>
          {b('Le Uchina-di club ')}
          <div className="list-disc ml-6">
            <li className={generalText}>
              Dojo de Grégory Guérin
              <div className="list-disc ml-6">
                <li className={generalText}>
                  {link(
                    'Page Internet',
                    'http://uchinadiclub.com/'
                  )}
                </li>
                <li className={generalText}>
                  {link(
                    'Page Facebook',
                    'https://www.facebook.com/Uchina-di-club-karat%C3%A9-et-kobudo-dOkinawa-1485281791613789/'
                  )}
                </li>
                <li className={generalText}>
                  Adresse : 3 Rue du Martin Pêcheur, 92190 Meudon, France
                </li>
              </div>
            </li>
          </div>
        </li>
      </div>

      <div className="mt-2 list-disc">
        <li className={generalText}>
          {b('Le Okinawa Karate et Kobudo Club (OKKC)')}
          <div className="list-disc ml-6">
            <li className={generalText}>
              Dojo de Patrick Leclere
              <div className="list-disc ml-6">
                <li className={generalText}>
                  {link(
                    'Page Internet',
                    'http://okinawakaratekobudoclub.blogspot.com/'
                  )}
                </li>
                <li className={generalText}>
                  {link(
                    'Page Facebook',
                    'https://www.facebook.com/okkc.leclere'
                  )}
                </li>
                <li className={generalText}>
                  Adresse : Espace Gilles Servat, dojo Sud Bretagne, 56400 MERIADEC / PLUNERET, Bretagne, France
                </li>
              </div>
            </li>
          </div>
        </li>
      </div>

      <div className="mt-2 list-disc">
        <li className={generalText}>
          {b('Le Okinawa te traditionel dojo')}
          <div className="list-disc ml-6">
            <li className={generalText}>
              Dojo de Patrice Merckel
              <div className="list-disc ml-6">
                <li className={generalText}>
                  {link(
                    'Page Internet',
                    'http://www.okinawa-te.org/pages/dojo.htm'
                  )}
                </li>
                <li className={generalText}>
                  Addresse:
                  <div className="list-disc ml-6">
                    <li className={generalText}>
                      Dojo à Saint Sauveur : Rue Just Pingand, Sous sol de
                      l'école maternelle 70300 St SAUVEUR
                    </li>
                    
                    <li className={generalText}>
                      Gymnase des Merises : Rue Marcel Donjon 70300 LUXEUIL LES
                      BAINS
                    </li>
                  </div>
                </li>
              </div>
            </li>
          </div>
        </li>
      </div>
      {BigImage(Carte)}
      <div className="w-full flex justify-around my-4">
      </div>
      </Wrapper>
  );
}
