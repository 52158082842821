import React from 'react';

import {
  SubHeader,
  generalText,
  Table,
  SmallImage,
  blank,
  sep,
} from '../components';

import Wrapper from './ScreenWrapper';

import bo from '../assets/kobudo/bo.jpg';
import sai from '../assets/kobudo/sai.jpg';
import manji from '../assets/kobudo/Manji_sai.jpg';
import tunfa from '../assets/kobudo/tunfa.jpg';
import nunchaku from '../assets/kobudo/nunchaku.jpg';
import eku from '../assets/kobudo/eku.jpg';
import kama from '../assets/kobudo/kama.jpg';
import sansetsukon from '../assets/kobudo/sansetsukon.jpg';
import nunti from '../assets/kobudo/nunti.jpg';
import timbe from '../assets/kobudo/timbe.jpg';
import kwe from '../assets/kobudo/kwe.jpg';
import muge from '../assets/kobudo/muge.jpg';
import cheval from '../assets/kobudo/Muge_cheval_ryu_kyu.jpg';
import tekko from '../assets/kobudo/tekko.jpg';
import suruchin from '../assets/kobudo/suruchin.jpg';

export default function Kobudo() {
  const t_class = 'border border-gray-800 p-2';

  return (
    <Wrapper>
      <div className={generalText}>
        Art de manier les armes, cette discipline propose un grand panel d'armes
        différentes.
      </div>
      {SubHeader("Le kobudo d'Okinawa")}{' '}
      <div className={generalText}>
      <br />
        Kobudo signifie « Art Martial Ancien » et regroupe les arts martiaux pratiqués avec des armes.
        Le Kobudo d’Okinawa au lieu d’utiliser des armes issues de la guerre (sabre, lance, arc…) 
        utilise lui des outils du quotidien, bâton, rame, fléau à riz, faucilles etc., transformés en armes d’auto-défense.
        Sur l’île d’Okinawa, la pratique des kobudo a longtemps été indissociable de la pratique du karaté, 
        c’est relativement récemment (après la guerre du Pacifique) que leur pratique a été différenciée et même 
        s’ils ne sont pas connus comme des experts en kobudo, la majorité des enseignants de karaté à Okinawa excellait 
        dans la pratique d’une à deux armes de kobudo en complément de leur pratique du karaté.
        <br />
        <br />
        En 1934, après avoir passé treize années à
        voyager en Asie pour étudier les armes, la boxe chinoise et la médecine
        traditionnelle, Shinko Matayoshi (1888-1947) créa la forme moderne du
        Kobudo. Il en regroupa les techniques existantes et les codifia tout en
        y intégrant les connaissances qu’il avait acquises au cours de ses
        différents voyages. Il commença à enseigner et entreprit de transmettre
        ses connaissances à son fils, Shimpo Matayoshi.
        <br />
        <br />
        Quand son père décède en 1947, Shimpo Matayoshi (1921-1997) est alors en
        mesure de succéder à son père et de poursuivre l’œuvre commencée.
        <br />
        <br />
        Neuf années plus tard, Shimpo Matayoshi créa son propre Dojô : le
        Kodokan, puis, en 1970, fonda la Fédération de Kobudo d'Okinawa.
        <br />
        <br />
        Il participa à de très nombreuses manifestations dont celle organisée en
        1976 pour la cérémonie de restitution d'Okinawa au Japon à Kagoshima.
        Maître Shimpo Matayoshi, véritable ambassadeur des Arts Martiaux
        d'Okinawa, voyagea beaucoup en Europe et aux Etats-Unis.
        <br />
        <br />
        ﻿En 1995, Maître Shimpo Matayoshi, vint en France, sur invitation de
        Maître Adaniya, pour diriger un stage de Kobudo au sein du Nippon Budo
        Club. Ceux et celles qui ont eu la chance de le côtoyer se souviennent
        avec émotion des qualités exceptionnelles de Maître Matayoshi.
        <br />
        <br />
        Décédé en 1997, Maître Shimpo Matayoshi laisse un grand vide dans la
        communauté des Kobudokas, et en particulier dans le cœur de ses
        disciples dont celui de Maître Seisuke Adaniya.
        <br />
        <br />
      </div>
      {SubHeader('Les armes du kobudo')}
      <div className={`w-full ${generalText}`}>
        {SmallImage(bo)}
        Le bō ou kon ou kun, est un très long bâton de 1 mètre 80, c'était
        l'instrument le plus répandu dans l'île d'okinawa. Il servait notamment
        au transport de lourdes charges.
        <br />
        Le Bo d'Okinawa se distingue des autres batons par sa forme originale en
        effet celui-ci est effilé aux extrémités.
        <br />
        C'est l'arme de base en kobudo et donc la première étudiée
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>棒術（棍法)</td>
            <td className={t_class}>Bô-jutsu (kunpô)</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>一の形</td>
            <td className={t_class}>Ichi no kata</td>
          </tr>
          <tr>
            <td className={t_class}>2</td>
            <td className={t_class}>周氏之棍</td>
            <td className={t_class}>Shushi nu kun</td>
          </tr>
          <tr>
            <td className={t_class}>3</td>
            <td className={t_class}>大屯棒</td>
            <td className={t_class}>Ufutun-bô</td>
          </tr>
          <tr>
            <td className={t_class}>4</td>
            <td className={t_class}>朝雲之棍</td>
            <td className={t_class}>Chô’un nu kun</td>
          </tr>
          <tr>
            <td className={t_class}>5</td>
            <td className={t_class}>佐久川之棍</td>
            <td className={t_class}>Sakugawa nu kun</td>
          </tr>
          <tr>
            <td className={t_class}>6</td>
            <td className={t_class}>津堅之棍</td>
            <td className={t_class}>Chikin nu kun</td>
          </tr>
          <tr>
            <td className={t_class}>7</td>
            <td className={t_class}>添石之棍</td>
            <td className={t_class}>Shishi nu kun</td>
          </tr>
          <tr>
            <td className={t_class}>8</td>
            <td className={t_class}>徳嶺之棍一</td>
            <td className={t_class}>Tokumine nu kun ichi</td>
          </tr>
          <tr>
            <td className={t_class}>9</td>
            <td className={t_class}>徳嶺之棍二</td>
            <td className={t_class}>Tokumine nu kun ni</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(sai)}
        Ces armes sont d'origine chinoise ou peut-être origine indonésienne. Ils
        sont généralement utilisés par trois, avec un troisième sai gardé à la
        ceinture, prêt à en remplacer un ou être jetés.
        <br />
        <br />
        Le Sai peut être construit avec différentes formes et tailles, il peut
        donc être arrondi, lisse ou octogonal, répondant ainsi à toutes les
        demandes des utilisateurs.
        <br />
        <br />
        Les Sai sont utilisés comme une arme défensive ou d’attaque. Ils peuvent
        bloquer les coups ou attaquer brutalement en utilisant la forme centrale
        pointue. Il peut également être utilisé comme arme de jet dans un rayon
        de 5 mètres.
        <br />
        <br />
      </div>
      {sep()}
      {SubHeader('Variante')}
      <div className={`w-full ${generalText}`}>
        {SmallImage(manji)}
        Le manji sai par opposition au Sai les branches du Manji sai sont
        asymétriques et peuvent être utilisés par paire comme armes de lancé.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>釵術</td>
            <td className={t_class}>Sai-jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>一の形</td>
            <td className={t_class}>Ichi no kata</td>
          </tr>
          <tr>
            <td className={t_class}>2</td>
            <td className={t_class}>千原之釵</td>
            <td className={t_class}>Chinbaru nu sai</td>
          </tr>
          <tr>
            <td className={t_class}>3</td>
            <td className={t_class}>二丁釵</td>
            <td className={t_class}>Nichô-sai</td>
          </tr>
          <tr>
            <td className={t_class}>4</td>
            <td className={t_class}>三丁釵</td>
            <td className={t_class}>Sanchô-sai</td>
          </tr>
          <tr>
            <td className={t_class}>5</td>
            <td className={t_class}>釵一</td>
            <td className={t_class}>Sai ichi</td>
          </tr>
          <tr>
            <td className={t_class}>6</td>
            <td className={t_class}>釵二</td>
            <td className={t_class}>Sai ni</td>
          </tr>
          <tr>
            <td className={t_class}>7</td>
            <td className={t_class}>釵三</td>
            <td className={t_class}>Sai san</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(tunfa)}
        Également appelé Tonfa, tuifa, ou sonkua. Cette arme découle de la
        poignée d'un petit moulin en pierre utilisé pour moudre le grain. Les
        matraques des officiers de police s'inspirent de cette arme.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>柱枴術 (トンクワァー術)</td>
            <td className={t_class}>Tunkwa-jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>一の形</td>
            <td className={t_class}>Ichi no kata</td>
          </tr>
          <tr>
            <td className={t_class}>2</td>
            <td className={t_class}>二の形</td>
            <td className={t_class}>Ni no kata</td>
          </tr>
          <tr>
            <td className={t_class}>3</td>
            <td className={t_class}>当山の柱枴</td>
            <td className={t_class}>Toyama no tunfa</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(nunchaku)}
        L'origine de cette arme est mystérieuse. Une théorie dit qu'elle a été 
        obtenue sur la base des fléaux à battre le riz. Une autre, dit qu'elle a 
        été dérivée et modifiée d’une très ancienne arme chinoise. 
        Bien que de nombreuses écoles utilisent une grande quantité de mouvement 
        démonstratif avec le nunchaku, lors des impacts le rebond rend très difficile 
        à contrôler. Les techniques de combats sont souvent très simples et direct. 
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>双節棍術 (ヌンチァク術)</td>
            <td className={t_class}>Nunchaku-jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>一の形</td>
            <td className={t_class}>Ichi no kata</td>
          </tr>
          <tr>
            <td className={t_class}>2</td>
            <td className={t_class}>二の形</td>
            <td className={t_class}>Ni no kata</td>
          </tr>          
        </Table>
      </div>
      {sep()}
      {SubHeader('Variante')}
      <div className={`w-full ${generalText}`}>
        {SmallImage(muge)}
        Considéré comme l'ancêtre du nunchaku, il était le mords des chevaux
        pour les guider lors des déplacements.
        {SmallImage(cheval)}
        Retiré, il utilise des techniques puissantes d'écrasement avec sa
        section particulière au centre de celui-ci.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>ムーゲー術</td>
            <td className={t_class}>Muge justu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>ムーゲーの形</td>
            <td className={t_class}>Muge no kata</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(eku)}
        Le eku est utilisé par les pêcheurs lors de conflits entre eux ou
        contres les envahisseurs, les techniques de défense de cette armes ont
        la particularité d'être tranchantes.
        <br />
        Le Eku est composé d'une partie plate pouvant être employé pour projeter
        du sable.
        <br />
        <br />
        Certaines légendes d'Okinawa font état de pêcheurs ayant décapités des
        adversaires avec la partie plate de l'Eku.
        <br />
        Le nom du kata est en hommage à un pêcheur de l'île Tsuken Jima du nom
        de Azato surnommé Akatsu (le rougeaud) en raison d'une tache de vin.
        <br />
        <br />
        Des pagaies similaires sont encore visibles partout dans le sud du Japon
        et à Okinawa lors des festivals de courses de bateaux et des danses de
        villages, et sont utilisés comme des pagaies d'urgence dans toutes
        sortes de bateaux.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>櫂術術 (ウェーク手)</td>
            <td className={t_class}>Uêku-di</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>津堅赤人之櫂手</td>
            <td className={t_class}>Chikin Akatsu nu eku di</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(kama)}
        Ce sont des faucilles standard utilisées pour la récolte de riz et
        d'autres productions agricoles et aussi pour le jardinage. On les
        utilisent encore de nos jours et on peut en trouver dans les rayons de
        jardinage.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>鎌術</td>
            <td className={t_class}>Kama jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>鎌之手</td>
            <td className={t_class}>Kama nu ti</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(sansetsukon)}
        Cela signifie "bâton à trois sections», est aussi appelé Shorin
        (Shaolin) Sansetsukon.
        <br />
        <br />
        C'est une arme d'origine chinoise constituée de trois morceaux de bois
        ou de métal, reliés entre eux par une corde ou des anneaux métalliques.
        <br />
        <br />
        C'est une version plus grande et plus complexe que le nunchaku.
        <br />
        <br />
        Cette arme est très ancienne puisqu'elle est mentionnée dans les
        Chroniques des Trois Royaumes écrites au IIIe siècle.
        <br />
        <br />
        Cette arme permettait de frapper à de plus longues distances que le
        nunchaku. Le combattant pouvait en outre se protéger avec cette arme en
        lui faisant décrire deux cercles sur ses côtés.
        <br />
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>三節棍術</td>
            <td className={t_class}>Sansetsukon jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>三節棍え形</td>
            <td className={t_class}>Sansetsukon no kata</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(nunti)}
        Le Nunti est une arme introduite de Chine il y a 600 ans, époque ou les
        échanges commerciaux entre Okinawa et le continent furent très
        important.
        <br />
        Le bâton mesure 170cm et à son extrémité se trouve le manji-sai.
        <br />
        Indépendamment du Nunti le pratiquant porte 2 manji-sai au niveau des
        reins dans le but de pouvoir les lancer.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>貫手術 (ヌンティ術）</td>
            <td className={t_class}>Nunti jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>貫手の形</td>
            <td className={t_class}>Nunti no kata</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(timbe)}
        Le timbe est un bouclier de cuir sur une structure en roseaux ou en
        métal, le seiryuto est une machette. Le timbe est utilisé pour cacher le
        seiryuto à la vue, se défendre et se couvrir. Il sert aussi à projeter
        du sable ou de la saleté au visage de son adversaire.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>藤牌術　(ティンべー術）</td>
            <td className={t_class}>Tinbê jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>藤牌の形</td>
            <td className={t_class}>Tinbê no kata</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(kwe)}
        Également appelé kwa. C'est une lourde houe agricole. Elle a une hauteur
        d'environ 1m et une lame d'environ 20 cm de long et 10 cm de large.
        <br />
        <br />
        Une bêche à pomme de terre avec 3 ou 4 pointes, comme un râteau au lieu
        d'une seule lame, est également utilisé de la même manière.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>鍬術 (クェ之手)</td>
            <td className={t_class}>Kwe-di</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>鍬之手</td>
            <td className={t_class}>Kwe nu ti</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(tekko)}
        Également appelé tetsuko. Cette arme est censée être dérivé des étriers
        de la selle de chevaux ou des fers à chevaux. Les extrémités des étriers
        ont une bosse en forme de diamant qui peut être utilisé comme marteau,
        (et à amplifier les effets d'un poing marteau), en haut de la courbe la
        section est soit lisse ou soit avec de petits clous.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>鉄甲術</td>
            <td className={t_class}>Tekkô jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>鉄甲え形</td>
            <td className={t_class}>Tekkô no kata</td>
          </tr>
        </Table>
      </div>
      {sep()}
      <div className={`w-full ${generalText}`}>
        {SmallImage(suruchin)}
        Il s'agit d'une corde de 2-3 mètres de long avec des pierres en forme de
        goutte liés à chaque extrémité. Le style d'utilisation est dérivé de
        sources de Chine et d'Okinawa.
        <Table>
          <tr>
            <td className={t_class}></td>
            <td className={t_class}>スルチン術</td>
            <td className={t_class}>Suruchin jutsu</td>
          </tr>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>スルウチンえ形</td>
            <td className={t_class}>Suruchin no kata</td>
          </tr>
        </Table>
      </div>
      {blank()}
    </Wrapper>
  );
}
