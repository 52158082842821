import React from 'react';

export default function Wrapper({ children }) {
  return (
    <div className="sm:w-5/6 w-screen flex flex-col sm:items-start items-center">
      <div className="sm:w-5/6 w-11/12 mx-auto sm:h-large sm:overflow-y-scroll flex flex-col sm:items-start  px-2">
        {children}
      </div>
    </div>
  );
}
