import React from 'react';

import {
  Header,
  SubHeader,
  generalText,
  b,  
  link,
  buttonLink,
  Player,
} from '../components';
import Wrapper from './ScreenWrapper';

export default function Home() {
  return (
    <Wrapper>
      <div>
      {Player('https://www.youtube.com/watch?v=yxZ87li8t64')}
      </div>

      {SubHeader('Informations')}
      <div className="mt-2">
         <div className="mt-2">
         <li className={generalText}>
          Karaté adultes le lundi de 20h15 à 21h45
        </li>
        <li className={generalText}>
          Karaté adultes et enfants à partir de 7 ans le samedi de 13h30 à 14h30
        </li>
        <li className={generalText}>
          Kobudo adultes et adolescents à partir de 14 ans le mardi de 20h45 à 22h00 et le samedi de 14h30 à
          15h30
        </li>
      </div>
      <div className={`mt-2 ${generalText}`}>
        <div>
          {b('Tarif')}: 70€ par trimestre + 15€ de frais de dossier + 37€ de
          licence/assurance. Téléchargez dès maintenant :
          
        </div>  
          <div className="sm:my-4 my-2 flex justify-center w-full">
          {buttonLink("Le formulaire d'inscription", "http://uchinadiclub.com/images/Fiche%20d'inscription.pdf")}
          </div> 
          
          <div className="sm:my-4 my-2 flex justify-center w-full">
          {buttonLink("La demande de licence", "http://uchinadiclub.com/images/Demande-de-licence.pdf")}
          </div> 

          <div className="sm:my-4 my-2 flex justify-center w-full">
        {buttonLink("Cours d'essai", 'mailto:bureau@uchinadiclub.com')}
      </div>
        
       
         
        </div>
      </div>
          
     
    </Wrapper>
  );
}
