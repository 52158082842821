import React from 'react';
import Wrapper from './ScreenWrapper';
import {
  Header,
  generalText,
  Player,
  SubHeader,
  SubHeader2,
} from '../components';

export default function Videoskobudo() {
  return (
    <Wrapper>
      {Header('Présentation')}
      <div className={generalText}>
        Présentation du kobudo d'Okinawa :
      </div>
      {SubHeader('Vidéos Kihon et Kata kobudo')}

      {SubHeader2('Bo')}
      {Player('https://youtu.be/UV-CnYfGBUU')}
      {Player('https://youtu.be/HJOWbsS6G_4')}
      {Player('https://youtu.be/qp0DAN8VUGg')}
      {Player('https://youtu.be/ovouTZPCvIw')}

      {SubHeader2('Tunfa')}
      {Player('https://youtu.be/tnPOp55NOVY')}
      {Player('https://youtu.be/QaP91fiNhBE')}
      {Player('https://youtu.be/7TkwSeM42ZA')}

      {SubHeader2('sai')}
      {Player('https://youtu.be/dQL8eoRsovE')}
      {Player('https://youtu.be/BXkEg-y7D8A')}
      {Player('https://youtu.be/AvHU-W-vS6E')}

      {SubHeader2('Nunchaku')}
      {Player('https://youtu.be/VOOCc8-yj9M')}
      {Player('https://youtu.be/QymQP_SHMxk')}
      {Player('https://youtu.be/BMFKiJFLgwA')}
      </Wrapper>
  );
}
