import React from 'react';

import { Header, generalText, b, BigImage } from '../components';
import Wrapper from './ScreenWrapper';

import dojoprof from '../assets/dojoprof.jpg';

export default function Professeur() {
  return (
    <Wrapper>
      {Header('Le Professeur')}
      <div className={generalText}>
        {b('Grégory GUÉRIN')}, {b('5e DAN de karaté et 5e DAN de kobudo')} (grades
        également reconnus au Japon) est un pratiquant de ces disciplines depuis
        plus de 30 ans. Il est titulaire d’un brevet d’État d’éducateur
        spécialisé (BEES) 1er degré, spécialité karaté.
        <br />
        <br /> Grégory Guérin commence le karaté Shorin-ryu à l’âge de 6 ans
        auprès de sensei Adaniya, 9e dan de karaté et de kobudo, et expert
        fédéral, au club de Levallois-Perret. À l’âge de 14 ans, il débute le
        kobudo. Il s’entraîne depuis assidûment avec ce grand maître.
        <br />
        <br /> Grâce à sensei Adaniya, il effectue plusieurs voyages à Okinawa
        où il rencontre grands maîtres et experts tels que les sensei Nakazato
        Shuguro, pour le karaté Shorin-ryu, et Matayoshi Shimpo pour le kobudo.
        Lors de ces séjours au berceau du karaté, il passe ses grades de 2e et
        3e dan de kobudo et participe à des compétitions internationales.
        <br />
        <br /> Depuis plus de 15 ans, il assiste sensei Adaniya dans son club
        situé dans le 14e arrondissement de Paris. Il décide aujourd'hui
        d'ouvrir un club à Meudon afin de transmettre l’enseignement de son
        maître. Comme son sensei, il est un chercheur et un praticien passionné.
        <br />
        <br />
        Il garde toujours à l’esprit que les arts martiaux sont et doivent
        rester des moyens de défendre et de préserver toutes vies.
      </div>
      {BigImage(dojoprof)}
    </Wrapper>
  );
}
