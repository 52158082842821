import React from 'react';
import YouTubePlayer from 'react-player';

const Header = (value) => (
  <div className="font-raleway w-full text-center sm:text-4xl text-xl text-gray-700">
    {value}
    </div>
);

const SubHeader = (value) => (
  <div className="font-raleway sm:text-2xl text-lg text-gray-600 underline">
    {value}
  </div>
);

const SubHeader2 = (value) => (
  <div className="font-raleway sm:text-xl text-md text-gray-600 underline">
    {value}
  </div>
);

const generalText = 'font-mont font-bold text-gray-800 sm:text-sm text-xs';

const b = (value) => (
  <bold className="font-extrabold text-super font-raleway">{value}</bold>
);

const link = (value, link) => (
  <a
    href={link}
    className={`${generalText} underline hover:text-gray-600 focus:text-gray-600`}
  >
    {value}
  </a>
);

const buttonLink = (value, link) => (
  <a
    href={link}
    className={`${generalText} px-4 py-2 bg-gray-400 hover:bg-gray-600 bg:text-gray-600 rounded`}
  >
    {value}
  </a>
);

const Image = (source) => (
  <img
    className="my-2 mx-auto sm:w-2/5 object-scale-down"
    src={source}
    alt=""
  />
);

const BigImage = (source) => (
  <img
    className="my-2 mx-auto sm:w-4/5 object-scale-down"
    src={source}
    alt=""
  />
);

const SmallImage = (source) => (
  <img
    className="my-2 mx-auto sm:w-1/6 w-1/4 object-scale-down"
    src={source}
    alt=""
  />
);

const blank = () => <div className="w-full h-mini opacity-0">.</div>;

const sep = () => (
  <div className="my-4 w-5/6 rounded text-xs bg-gray-800 text-gray-800 opacity-50 mx-auto">
    -
  </div>
);

const Player = (url) => (
  <div className="sm:my-8 my-2 flex justify-center w-full">
    <YouTubePlayer url={url} />
  </div>
);

const Table = ({ children }) => (
  <div className="w-full mt-2">
    <table className="table-auto mx-auto">
      <tbody className="w-full">{children}</tbody>
    </table>
  </div>
);

export {
  Header,
  SubHeader,
  SubHeader2,
  generalText,
  b,
  link,
  buttonLink,
  Image,
  BigImage,
  SmallImage,
  blank,
  sep,
  Table,
  Player,
};
