import React from 'react';

import { SubHeader, generalText, link, BigImage } from '../components';
import Wrapper from './ScreenWrapper';

import sensei from '../assets/sensei_Adaniya_NAMT.jpg';

export default function Adaniya() {
  return (
    <Wrapper>
      {SubHeader('Sensei Seisuke ADANIYA')}{' '}
      <div className={generalText}>
        Senseï Seisuke Adaniya est né à Okinawa, quelques années après la
        guerre. Passionné d’Arts Martiaux il commence par pratiquer le judo et
        le kendo, mais très vite, il s’initie au Karaté et au Kobudo. Après un
        court passage par le style Uechi Ryu, il se dirige définitivement vers
        le Shorin Ryu, dont les spécificités correspondent mieux à ses
        aspirations.
        <br />
        <br /> Pendant de nombreuses années, il s’entraîne assidûment, en karaté
        Shorin Ryu, avec Maître Shugoro NAKAZATO et en Kobudo, avec Maître
        Shimpo MATAYOSHI : deux grands Maîtres okinawaiens qui seront ses
        modèles et dont il appliquera les préceptes tout au long de sa vie.
        <br />
        <br />
        Vers la fin des années 1970, Maître Adaniya vient en France pour y
        enseigner les Arts Martiaux d’Okinawa et, finalement, décide d’y rester
        pour y fonder une famille. C’est alors le temps du passage de ses divers
        diplômes nationaux qui lui donneront la légitimité nécessaire pour
        enseigner sur le territoire français. En 1987, avec son épouse, il crée
        le {link('NIPPON BUDO CLUB', 'https://www.nipponbudoclub.com')}.
        <br />
        <br /> Après presque 50 ans d’un entraînement quotidien mené dans le
        respect absolu des traditions d’Okinawa, Maître Adaniya pourrait
        s’enorgueillir d’avoir acquis une expérience solide, mais ce n’est pas
        bien le connaître que de croire qu’il va se contenter de ce qu’il a
        appris, au contraire, il pense qu’il peut toujours mieux faire…
        <br />
        <br /> C’est, d’ailleurs, dans cette perspective, qu’il y a 20 ans, il a
        décidé de démarrer des recherches sur les origines du karaté
        traditionnel pour en comprendre les techniques tenues secrètes et en
        apprendre les principes fondateurs.
        <br />
        <br /> Après avoir analysé sa propre pratique et travaillé avec les
        meilleurs experts qu’il ait pu rencontrer, il a maintenant décrypté les
        mécanismes régissant le renforcement de l’équilibre et le développement
        de la force interne. Devenir plus fort tout en protégeant son capital
        santé est la voie que Maitre Adaniya à choisi.​​
        <br />
        <br />
        Après 50 ans de pratique et 20 ans de recherches, il a atteint son
        objectif et est en mesure, maintenant, de le matérialiser à travers une
        méthode d’entraînement (bientôt terminée) dont il commence à voir les
        effets bénéfiques sur ses propres élèves.​
        <br />
        <br />
        Pour conclure et mieux comprendre l’état d’esprit de Maître Adaniya,
        voici quelques-unes des règles qu’il s’applique à lui-même:
        <div className="mt-2 list-decimal">
          <li className={generalText}>
            Travailler régulièrement : s’entraîner le plus souvent possible pour
            garder (ou retrouver) une bonne condition physique et pour pouvoir
            mémoriser l’apprentissage des techniques.
          </li>
          <li className={generalText}>
            Faire preuve de patience : accepter de répéter indéfiniment les
            techniques de base afin d’automatiser les gestes et d’en ressentir
            les effets pour pouvoir les perfectionner.
          </li>
          <li className={generalText}>
            S’entraîner avec humilité : apprendre avec simplicité, en acceptant
            les conseils. Ne jamais penser « je sais » car il n’y a pas
            d’obstacle plus efficace pour stopper définitivement toute
            progression.
          </li>
          <li className={generalText}>
            Respecter les traditions : les « Anciens » avaient déjà tout
            découvert dans ce domaine et c’est les honorer que de respecter leur
            mémoire.
          </li>
        </div>
      </div>
      {BigImage(sensei)}
    </Wrapper>
  );
}
