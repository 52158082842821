import React from 'react';
import Wrapper from './ScreenWrapper';
import {
  Header,
  generalText,
  Player,
  SubHeader,
  SubHeader2,
} from '../components';

export default function Videoskarate() {
  return (
    <Wrapper>
      {Header('Présentation')}
      <div className={generalText}>
        Présentation du karaté Shorin-ryu :
      </div>
      {SubHeader('Vidéos Kata karaté Shorin-ryu')}
      {SubHeader2('Pinan')}
      {Player('https://youtu.be/3j01k9mjWhs')}
      {Player('https://youtu.be/rOlgHQA2CfQ')}
      {Player('https://youtu.be/iWEBnp1rhFc')}
      {Player('https://youtu.be/ehW5iZBIQKs')}
      {Player('https://youtu.be/4-5ciHFdLzI')}
      {SubHeader2('Nahanshi')}
      {Player('https://youtu.be/3qkik4f4fAM')}
      {Player('https://youtu.be/x6KGUZHtN9w')}
      {Player('https://youtu.be/Qi_KRlMnRBo')}
      {SubHeader2('Passai')}
      {Player('https://youtu.be/MmoKgbDyHuY')}
      {Player('https://youtu.be/7eCl-aMJxLE')}
      {Player('https://youtu.be/VUxhO8z3oGk')}
      {SubHeader2('Kusanku')}
      {Player('https://youtu.be/22EhjJD_q8c')}
      {Player('https://youtu.be/iCUh9D1F92A')}
      {SubHeader2('Jion')}
      {Player('https://youtu.be/M3hE_B99SAc')}
      {SubHeader2('Jitté')}
      {Player('https://youtu.be/YTauqm97bFY')}
      {SubHeader2('Chinto')}
      {Player('https://youtu.be/aeRLlmSMb-Q')}
      {SubHeader2('Gojushiho')}
      {Player('https://youtu.be/Ym_cYkCMnAI')}
    </Wrapper>
  );
}
