import React from 'react';
import Wrapper from './ScreenWrapper';
import {
  Header,
  generalText,
  Player,
  SubHeader,
  SubHeader2,
} from '../components';

export default function Videos() {
  return (
    <Wrapper>
      {Header('Présentation')}
      <div className={generalText}>
        Présentation du karaté Shorin-ryu et du kobudo d'Okinawa:
      </div>
      {Player('https://youtu.be/QPYiYCMVrJ8')}
      {Player('https://youtu.be/yxZ87li8t64')}
       </Wrapper>
  );
}
