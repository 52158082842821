import React from 'react';

import { SubHeader, generalText, Table } from '../components';

import Wrapper from './ScreenWrapper';

export default function Karate() {
  const t_class = 'border border-gray-800 p-2';

  return (
    <Wrapper>
      <div className={generalText}>
        Ce style ancien est souple, varié et particuliérement efficace pour le
        combat rapproché.
      </div>
      {SubHeader('Le shorin-ryu')}{' '}
      <div className={generalText}>
        Le style pratiqué au Uchina-di Club est le Shorin Ryu, style d’Okinawa.
        <br />
        Shorin est la lecture okinawaienne de Shaolin, par référence au fameux
        style d'origine chinoise.
        <br />
        Le Shorin Ryu regroupe essentiellement des tendances issues du Shuri Te
        et quelques apports pris au Tomari Te.
        <br />
        <br />
        Le Shorin Ryu est divisé en trois branches principales:
        <div className="mt-2">
          <li>Le Sukunai Hayashi Ryu</li>
          <li>Le Kobayashi Ryu</li>
          <li>Le Matsubayashi Ryu</li>
        </div>
      </div>
      <div className={`${generalText} mt-2`}>
        Le Uchina-di Club est rattaché à la branche Kobayashi Ryu, nom choisi
        par Shochin Chibana (1885-1969) important disciple d’Anko Itosu, au même
        titre que Gishin Funakoshi (créateur du style Shotokan) ou Kenwa Mabuni
        (créateur du style Shito Ryu).
        <br />
        <br />
        Shochin Chibana figure parfois dans certaines généalogies sous son nom
        japonais (Chojin Kuba). Dès l'âge de quinze ans, Maître Chibana fut le
        disciple de Maître Itosu, grande légende du karaté à qui l’on doit la
        série des katas "Pinan" et l’introduction des leçons de karaté dans les
        écoles primaires et secondaires d'Okinawa.
        <br />
        <br />A trente-cinq ans, en 1920 Shoshin Chibana crée le style Kobayashi
        Ryu et ouvre un dojo dans la ville de Shuri. Enseignant de très grande
        réputation, Maître Chibana fut le premier président de l'Okinawa Karate
        Do Renmei, fondé en 1956 et de l'Okinawa Shorin Ryu Karate Kyokai, créé
        en 1961. Il s'éteignit en 1969 à l'âge de quatre-vingt-quatre ans.{' '}
        <br />
        <br />
        Ses trois principaux disciples étaient : Yuchoku Higa, Shuguro Nakazato
        (Maître de Sensei Adaniya) et Katsuya Miyahira.
        <br />
        <br />
        Maître Shuguro Nakazato, né en 1920, est l’un des grands maîtres actuels
        du Shorin Ryu d'Okinawa. Il découvre le karaté en 1935 avec Iju Seiichi.
        Après la guerre, en 1945, il devient l'élève de Maître Chibana. En 1951
        il ouvre son premier Dojo (Chibana dai ichi dojo) et en 1955 il reçoit
        des mains de son maître le titre de "Shihan" et ouvre un second dojo à
        Aja : le « Shorinkan » (maison du Shorin).
        <br />
        <br />
        En 2000, Maître Shuguro Nakazato est honoré du titre de « Trésor
        Culturel vivant d’Okinawa» par le Gouvernement Japonais, en
        reconnaissance de son savoir et de sa longue expérience au service des
        Arts martiaux d’Okinawa.
      </div>
      {SubHeader('Kata')}{' '}
      <div className={`w-full ${generalText}`}>
        Il existe 5 kata de bases appelés PINAN.
        <Table>
          <tr>
            <td className={t_class}>1</td>
            <td className={t_class}>平安初段</td>
            <td className={t_class}>Pinan Shodan</td>
          </tr>
          <tr>
            <td className={t_class}>2</td>
            <td className={t_class}>平安二段</td>
            <td className={t_class}>Pinan Nidan</td>
          </tr>
          <tr>
            <td className={t_class}>3</td>
            <td className={t_class}>平安三段</td>
            <td className={t_class}>Pinan Sandan</td>
          </tr>
          <tr>
            <td className={t_class}>4</td>
            <td className={t_class}>平安四段</td>
            <td className={t_class}>Pinan Yondan</td>
          </tr>
          <tr>
            <td className={t_class}>5</td>
            <td className={t_class}>平安五段</td>
            <td className={t_class}>Pinan Godan</td>
          </tr>
        </Table>
      </div>
      <div className={`w-full mt-2 ${generalText}`}>
        Ensuite 3 kata de construction du corps et de base du style shorin-ryu
        appelés NAIHANCHI.
        <Table>
          <tr>
            <td className={t_class}>6</td>
            <td className={t_class}>ナイファンチ初段</td>
            <td className={t_class}>Naïhanchi Shodan</td>
          </tr>
          <tr>
            <td className={t_class}>7</td>
            <td className={t_class}>ナイファンチ二段</td>
            <td className={t_class}>Naïhanchi Nidan</td>
          </tr>
          <tr>
            <td className={t_class}>8</td>
            <td className={t_class}>ナイファンチ三段</td>
            <td className={t_class}>Naïhanchi Sandan</td>
          </tr>
        </Table>
      </div>
      <div className={`w-full mt-2 mb-4 ${generalText}`}>
        Après ceux-ci, on accède aux kata dit supérieurs.
        <Table>
          <tr>
            <td className={t_class}>9</td>
            <td className={t_class}>糸洲拔塞</td>
            <td className={t_class}>Itosu Passaï</td>
          </tr>
          <tr>
            <td className={t_class}>10</td>
            <td className={t_class}>松村拔塞</td>
            <td className={t_class}>Matsumura Passaï</td>
          </tr>
          <tr>
            <td className={t_class}>11</td>
            <td className={t_class}>泊拔塞</td>
            <td className={t_class}>Tomari passaï</td>
          </tr>
          <tr>
            <td className={t_class}>12</td>
            <td className={t_class}>公相君小</td>
            <td className={t_class}>Kûsanku shō</td>
          </tr>
          <tr>
            <td className={t_class}>13</td>
            <td className={t_class}>公相君大</td>
            <td className={t_class}>Kûsanku daï</td>
          </tr>
          <tr>
            <td className={t_class}>14</td>
            <td className={t_class}>慈恩</td>
            <td className={t_class}>Jion</td>
          </tr>
          <tr>
            <td className={t_class}>15</td>
            <td className={t_class}>十手</td>
            <td className={t_class}>Jitte</td>
          </tr>
          <tr>
            <td className={t_class}>16</td>
            <td className={t_class}>鎮東</td>
            <td className={t_class}>Chintō</td>
          </tr>
          <tr>
            <td className={t_class}>17</td>
            <td className={t_class}>五十四歩</td>
            <td className={t_class}>Gojushihō</td>
          </tr>
        </Table>
      </div>
    </Wrapper>
  );
}
